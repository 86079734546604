import * as React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/Layout"
import '../styles/styles.scss'
import * as styles from "../styles/tequila-single.module.scss"
import Pina from '../images/inline/pina.inline.svg'
import AgaveRoot from '../images/inline/agave_root.inline.svg'
import RedLine from '../images/inline/red_line.inline.svg'
import ThreeYears from '../images/inline/three_years.inline.svg'
// inicio iconos our features
import Barrel from '../images/inline/sin.inline.svg'
import Color from '../images/inline/color.inline.svg'
import Origen from '../images/inline/origen.inline.svg'
import Materia from '../images/inline/materia.inline.svg'
import Volumen from '../images/inline/volumen.inline.svg'
import Alcohol from '../images/inline/alcohol.inline.svg'
import Barricas from '../images/inline/barricas.inline.svg'
// Fin iconos our features
// Inicio iconos production process
import TheAgaveIcon from '../images/inline/icon-agave.inline.svg'
import CoccionIcon from '../images/inline/coccion.inline.svg'
import MillingIcon from '../images/inline/icon-milling.inline.svg'
import FermentationIcon from '../images/inline/icon-fermentation.inline.svg'
import DestilationIcon from '../images/inline/icon-destilation.inline.svg'
import AgingIcon from '../images/inline/icon-aging.inline.svg'
import BottlingIcon from '../images/inline/icon-bottling.inline.svg'
// Fin iconos production process
import Notes from '../images/inline/notes.inline.svg'
import Arrow from '../images/inline/arrow.inline.svg'
import Logo from '../images/inline/logo.inline.svg'
import Sello from '../images/inline/sello.inline.svg'
import Info from '../images/inline/info.inline.svg'
import Close from '../images/inline/x.inline.svg'
import Est from '../images/inline/est.inline.svg'
import LM from '../images/inline/lm.inline.svg'

import {GatsbyImage, getImage, IGatsbyImageData, StaticImage} from "gatsby-plugin-image";
import {useState} from "react";
import {Trans, useI18next} from "gatsby-plugin-react-i18next";

interface Props {
    data: {
        markdownRemark: any
    }
}

const TequilasTemplate = ({data}: Props) => {
    const {languages, changeLanguage, language} = useI18next();
    const [modalActive, setModalActive] = useState('none');
    const {markdownRemark} = data
    const {frontmatter: tequila} = markdownRemark

    function showModal(modal: string): void {
        setModalActive(modal)
    }
    

    return (
        <Layout>
            <title>{tequila.title}</title>
            <div className={styles.singleCover}>
                <GatsbyImage className={styles.backgroundImage} alt={tequila.title}
                             image={getImage(tequila.cover_img) as IGatsbyImageData}/>
                <div className={styles.content}>
                    {/* <h4>{language === 'es' ? tequila.line : tequila.line_en}</h4> */}
                    <h1>
                        <Pina/>
                        {tequila.title}</h1>
                    {/*<ThreeYears className={styles.year}/>*/}
                </div>
            </div>
            <section className={styles.singleDescription}>
                <div className={styles.left}>
                    <GatsbyImage className={styles.bottle} alt={tequila.title}
                                 image={getImage(tequila.bottle_img) as IGatsbyImageData}/>
                </div>
                <div className={styles.right}>
                    <h3>{language === "es" ? tequila.line : tequila.line_en}</h3>
                    <h2>{tequila.title}</h2>
                    <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.description : tequila.description_en}}></p>
                    {/* El siguiente bloque de código se bloquea hasta que se tenga tienda online */}
                    {/* <p className={styles.last}>
                        <Pina/>
                        <b>{tequila.available > 0 ? tequila.available + ' ' : ''}
                            {tequila.available > 0 ?
                                <Trans>Available</Trans>
                                :
                                <Trans>Coming soon</Trans>
                            }
                        </b>
                    </p>
                    <a href="/"><Trans>Buy button</Trans></a> */}
                </div>
            </section>

            <section className={styles.features}>
                <div className="top">
                    <AgaveRoot className={styles.first}/>
                    <h5><Trans>Features</Trans></h5>
                    <StaticImage src={'../images/our-tequila/separator.png'} alt={'Tequila'}/>
                </div>
                <div className={styles.items}>
                    {tequila.color && tequila.color_en &&
                        <div className={styles.item}>
                            <Color/>
                            <p><b>Color</b>
                                {language === 'es' ? tequila.color : tequila.color_en}
                            </p>
                        </div>
                    }
                    {tequila.origen && tequila.origen_en &&
                        <div className={styles.item}>
                            <Origen/>
                            <p><b><Trans>Origin tequila</Trans></b>
                                {language === 'es' ? tequila.origen : tequila.origen_en}
                            </p>
                        </div>
                    }
                    {tequila.materia && tequila.materia_en &&
                        <div className={styles.item}>
                            <Materia/>
                            <p><b><Trans>Ingredients</Trans></b>
                                <span dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.materia : tequila.materia_en}}></span>
                            </p>
                        </div>
                    }
                    {tequila.volumen && tequila.volumen_en &&
                        <div className={styles.item}>
                            <Volumen/>
                            <p><b><Trans>Size</Trans></b>
                                {language === 'es' ? tequila.volumen : tequila.volumen_en}
                            </p>
                        </div>
                    }
                    {tequila.alcohol && tequila.alcohol_en &&
                        <div className={styles.item}>
                            <Alcohol/>
                            <p><b><Trans>Strength</Trans></b>
                                {language === 'es' ? tequila.alcohol : tequila.alcohol_en}
                            </p>
                        </div>
                    }
                    {tequila.barricas && tequila.barricas_en &&
                        <div className={styles.item}>
                            <Barrel/>
                            <p><b><Trans>Barrels</Trans></b>
                                {language === 'es' ? tequila.barricas : tequila.barricas_en}
                            </p>
                        </div>
                    }
                </div>
            </section>

            <section className={styles.notes}>
                {((language === 'es' && tequila.notes) || (language !== 'es' && tequila.notes_en)) &&
                    <section className={styles.singleNote}>
                        <div className={styles.left}>
                            <div className={styles.content}>
                                <h3>{tequila.title}</h3>
                                <h2>
                                    <Color/>
                                    <Trans>Tasting</Trans></h2>
                                <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.notes : tequila.notes_en}}></p>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <GatsbyImage className={styles.bottle} alt={tequila.title}
                                 image={getImage(tequila.note_img) as IGatsbyImageData}/>
                        </div>
                    </section>
                }
                {/* {((language === 'es' && tequila.notes_2) || (language !== 'es' && tequila.notes_2_en)) &&
                    <div className={styles.right}>
                        <Notes/>
                        <div className={styles.content}>
                            <h2><Trans>Different</Trans></h2>
                            <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.notes_2 : tequila.notes_2_en}}></p>
                        </div>
                        <StaticImage className={styles.image} src={'../images/our-tequila/notes.jpg'} alt={'Tequila'}/>
                    </div>
                } */}
            </section>
            
            {tequila.post == 'agave-spirits' ?(
                <></>
            ):(
                <>
                    <section className={styles.process} id={"processTequila"}>
                        {/* <AgaveRoot/> */}
                        <div className={styles.processLogo}>
                            <Logo/>
                        </div>
                        <h2><Trans>Production process</Trans></h2>
                        <div className={styles.subtitle}>
                            <span className={styles.leftText}><Trans>We made</Trans></span>
                            <span className={styles.arrow}>
                                        <Arrow/>
                                    </span>
                            <span className={styles.rightText}><Trans>THE TEQUILA THAT HAS DONE IT ALL</Trans></span>
                        </div>
                        <div className={styles.items}>
                            {tequila.jima_description && tequila.jima_description_en &&
                                <div className={styles.item}>
                                    <span className={styles.name}>
                                        <TheAgaveIcon/>
                                        <span className={styles.number}>01</span>
                                        <b><Trans>The agave</Trans></b>
                                        <p><Trans>The agave short</Trans></p>
                                        <button onClick={() => showModal('jima')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.coccion_description && tequila.coccion_description_en &&
                                <div className={styles.item}>
                                    <span className={styles.name}>
                                        <CoccionIcon/>
                                        <span className={styles.number}>02</span>
                                        <b><Trans>Cooking</Trans></b>
                                        <p><Trans>Cooking short</Trans></p>
                                        <button onClick={() => showModal('coccion')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.molienda_description && tequila.molienda_description &&
                                <div className={styles.item}>
                                    <span className={styles.name}>
                                        <MillingIcon/>
                                        <span className={styles.number}>03</span>
                                        <b><Trans>Milling</Trans></b>
                                        <p><Trans>Milling short</Trans></p>
                                        <button onClick={() => showModal('molienda')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.fermentacion_description && tequila.fermentacion_description_en &&
                                <div className={styles.item}>
                                    <span className={styles.name}>
                                        <FermentationIcon/>
                                        <span className={styles.number}>04</span>
                                        <b><Trans>Fermentation</Trans></b>
                                        <p><Trans>Fermentation short</Trans></p>
                                        <button onClick={() => showModal('fermentacion')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.destilacion_description && tequila.destilacion_description_en &&
                                <div className={styles.item}>
                                    <span className={styles.name}>
                                        <DestilationIcon/>
                                        <span className={styles.number}>05</span>
                                        <b><Trans>Destilation</Trans></b>
                                        <p><Trans>Destilation short</Trans></p>
                                        <button onClick={() => showModal('destilacion')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.reposo_description && tequila.reposo_description_en &&
                                <div className={styles.item}>
                                    <span className={styles.name}>
                                        <AgingIcon/>
                                        <span className={styles.number}>06</span>
                                        <b><Trans>Aging</Trans></b>
                                        <p><Trans>Aging short</Trans></p>
                                        <button onClick={() => showModal('reposo')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.ajuste_description && tequila.ajuste_description_en &&
                                <div className={styles.item}>
                                    {/* <span className={styles.arrow}>
                                        <Arrow/>
                                    </span> */}
                                    <span className={styles.name}>
                                        <BottlingIcon/>
                                        <span className={styles.number}>{tequila.reposo_description && tequila.reposo_description_en ? '07' : '06'}</span>
                                        <b><Trans>Bottling</Trans></b>
                                        <p><Trans>Bottling short</Trans></p>
                                        <button onClick={() => showModal('ajustes')} title={'click'}>
                                            <Trans>Learn more</Trans> <LM/>
                                        </button>
                                    </span>
                                </div>
                            }
                            {tequila.reposo_description && tequila.reposo_description_en &&
                                <div className={`${styles.item} ${styles.processImg}`}>
                                    <span className={styles.name}>
                                        <StaticImage className={styles.processimg} src={'../images/inline/process.webp'} alt={'process'}/>
                                    </span>
                                </div>
                            }
                        </div>

                    </section>
                    <section className={styles.enjoy}>
                        {/* <StaticImage className={styles.float} src={'../images/our-tequila/bg-letters.png'} alt={'Tequila'}/> */}

                        <div className={styles.left}>
                            <h4>
                                <Trans>Enjoy</Trans>
                            </h4>
                            <h2><Trans>Explore Title</Trans></h2>
                            <Trans>Explore Description</Trans>
                        </div>
                        <StaticImage className={styles.right} src={'../images/our-tequila/mojito.jpg'} alt={'Tequila'}/>

                    </section>
                    <section className={styles.ourEssence}>
                        <Logo/>
                        <h3><Trans>Our essence</Trans></h3>
                        <Link className={styles.link} to="/our-essence">
                            <Trans>Discover</Trans>
                            <RedLine/>
                        </Link>
                        <StaticImage className={styles.image} src={'../images/our-tequila/footer-bottle.jpg'} alt={'Tequila'}/>
                    </section>
                </>
            )}



            {modalActive != 'none' &&
                <div className={styles.popUp}>
                    <div className={styles.bg}>
                        <div className={styles.content}>
                            <button className={styles.close} onClick={() => setModalActive('none')}>
                                <Close/>
                            </button>
                            <div className={styles.top}>
                                {modalActive === 'jima' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/agave-new.webp'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'coccion' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/cooking-new.webp'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'molienda' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/milling-new.webp'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'fermentacion' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/fermentation-new.webp'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'destilacion' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/distillation-new.webp'}
                                                    alt={'tequila'}/>
                                }
                                {modalActive === 'reposo' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/aging-new.webp'}
                                                 alt={'tequila'}/>
                                }
                                {modalActive === 'ajustes' &&
                                    <StaticImage className={styles.image} src={'../images/our-tequila/modal-img/bottling-new.webp'}
                                                 alt={'tequila'}/>
                                }
                            </div>
                            <div className={styles.bottom}>
                                {modalActive === 'jima' &&
                                    <>
                                        <h4>
                                            <TheAgaveIcon/>
                                            <Trans>The agave</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.jima_description : tequila.jima_description_en}}></p>
                                    </>
                                }
                                {modalActive === 'coccion' &&
                                    <>
                                        <h4>
                                            <CoccionIcon/>
                                            <Trans>Cooking</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.coccion_description : tequila.coccion_description_en}}></p>

                                    </>
                                }
                                {modalActive === 'molienda' &&
                                    <>
                                        <h4>
                                            <MillingIcon/>
                                            <Trans>Milling</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.molienda_description : tequila.molienda_description_en}}></p>
                                    </>
                                }
                                {modalActive === 'fermentacion' &&
                                    <>
                                        <h4>
                                            <FermentationIcon/>
                                            <Trans>Fermentation</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.fermentacion_description : tequila.fermentacion_description_en}}></p>
                                    </>
                                }
                                {modalActive === 'destilacion' &&
                                    <>
                                        <h4>
                                            <DestilationIcon/>
                                            <Trans>Distillation</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.destilacion_description : tequila.destilacion_description_en}}></p>

                                    </>
                                }
                                {modalActive === 'reposo' &&
                                    <>
                                        <h4>
                                            <AgingIcon/>
                                            <Trans>Aging</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.reposo_description : tequila.reposo_description_en}}></p>

                                    </>
                                }
                                {modalActive === 'ajustes' &&
                                    <>
                                        <h4>
                                            <BottlingIcon/>
                                            <Trans>Bottling</Trans>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{__html: language === 'es' ? tequila.ajuste_description : tequila.ajuste_description_en}}></p>
                                    </>
                                }
                                {/* <Est/> */}
                                <div className={styles.sello_icon}>
                                    <Sello/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Layout>
    )
}

export const pageQuery = graphql`
  query($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        line
        post
        line_en
        title
        description
        description_en
        available
        color
        color_en
        origen
        origen_en
        materia
        materia_en
        volumen
        volumen_en
        alcohol
        alcohol_en
        barricas
        barricas_en
        notes
        notes_en
        notes_2
        notes_2_en
        jima_description
        jima_description_en
        coccion_description
        coccion_description_en
        molienda_description
        molienda_description_en
        fermentacion_description
        fermentacion_description_en
        destilacion_description
        destilacion_description_en
        reposo_description
        reposo_description_en
        ajuste_description
        ajuste_description_en
        bottle_img {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        note_img {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cover_img { 
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
  }
`

export default TequilasTemplate
